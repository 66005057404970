import { useEffect, useState } from 'react';

import { useApiAccess } from 'contexts/AccessProvider';
import { TransfersListDocument, useNewTransferSubscription, useTransfersListQuery } from 'gql';
import { useWindowSize } from 'hooks';
import { useNavigate } from 'react-router-dom';

import LatestTransferColumn from './LatestTransferColumn';

const useLatestExtrinsics = () => {
  const [selectedTab, setSelectedTab] = useState<'latest' | 'largest'>('latest');
  const Navigate = useNavigate();
  const { sessionKey } = useApiAccess();
  const { width } = useWindowSize();

  const { data: transferList, loading: transferListLoading } = useTransfersListQuery({
    variables: { sessionKey, isLargest: selectedTab === 'largest' },
    fetchPolicy: 'cache-and-network',
  });

  useNewTransferSubscription({
    variables: { isLargest: selectedTab === 'largest' },
    onData(options) {
      const updatedData = [
        options.data.data?.newTransfer,
        ...(transferList?.transfersList?.data || []),
      ];
      updatedData.splice(100);
      if (options.data.data?.newTransfer) {
        options.client.writeQuery({
          query: TransfersListDocument,
          data: {
            transfersList: {
              ...transferList?.transfersList,
              data: updatedData,
            },
          },
          variables: {
            sessionKey,
            isLargest: selectedTab === 'largest',
          },
          broadcast: true,
        });
      }
    },
  });

  const COLUMNS = LatestTransferColumn();

  useEffect(() => {
    const x = document?.getElementById('extrinsics')?.scrollTop;
    const height = width < 768 ? 125 : 70;
    const scrollByHeight =
      typeof x == 'number' && transferListLoading
        ? document?.getElementById('extrinsics')?.scrollHeight || 0
        : height;
    document?.getElementById('extrinsics')?.scrollBy({
      top: -scrollByHeight,
      behavior: 'smooth',
    });
  }, [transferList, width]);
  return { Navigate, transferListLoading, COLUMNS, transferList, selectedTab, setSelectedTab };
};

export default useLatestExtrinsics;
