import { useState } from 'react';

import { useWindowSize } from '@analog/ui/src/utils/useWindowSize';
import { Card, Table } from 'components';
import ExtrinsicsColumns from 'components/common/ExtrinsicsColumns';
import { Skeleton } from 'components/Skeleton';
import { DEFAULT_PAGE_SIZE } from 'consts';
import { useApiAccess } from 'contexts/AccessProvider';
import { useGetExtrinsicsQuery, useListEventsQuery } from 'gql';
import { usePagination } from 'hooks';
import { arrayOfSize } from 'utils';

import { useBlockDetails } from '../useBlockDetails';

import EventsColumns from './EventsColumns';
import Switches from './Switches';

function BlockRelationalData() {
  const { sessionKey } = useApiAccess();
  const [isChecked, setIsChecked] = useState(false);
  const { blockDetailsData } = useBlockDetails();
  const { isMobile } = useWindowSize();

  const {
    pageNumber: extrinsicsPageNumber,
    pageSize: extrinsicsPageSize,
    setTotalDataCount: setExtrinsicTotalPagesCount,
    PageController: extrinsicPageController,
  } = usePagination({ defaultPageSize: DEFAULT_PAGE_SIZE });

  const { data: extrinsicsData, loading: extrinsicsLoading } = useGetExtrinsicsQuery({
    variables: {
      blockNumber: blockDetailsData?.blockDetails?.number.toString(),
      sessionKey,
      page: {
        number: extrinsicsPageNumber,
        size: extrinsicsPageSize,
      },
    },
    onCompleted(data) {
      setExtrinsicTotalPagesCount(data.getExtrinsics.count);
    },
    skip: !blockDetailsData?.blockDetails?.number,
    fetchPolicy: 'cache-first',
  });

  const {
    pageNumber: eventPageNumber,
    pageSize: eventPageSize,
    setTotalDataCount: setEventTotalPagesCount,
    PageController: eventPageController,
  } = usePagination({ defaultPageSize: DEFAULT_PAGE_SIZE });

  const { data: eventData, loading: eventLoading } = useListEventsQuery({
    variables: {
      blockNumber: blockDetailsData?.blockDetails?.number.toString(),
      sessionKey,
      page: {
        number: eventPageNumber,
        size: eventPageSize,
      },
    },
    onCompleted(data) {
      setEventTotalPagesCount(data.listEvents.count);
    },
    skip: !isChecked,
    fetchPolicy: 'cache-first',
  });

  const EXTRINSICS_COLUMNS = ExtrinsicsColumns();

  const EVENTS_COLUMNS = EventsColumns({ isMobile });

  const isLoading = extrinsicsLoading || eventLoading;

  const data = isChecked ? eventData?.listEvents.data : extrinsicsData?.getExtrinsics.data;

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <Skeleton.Provider isLoading={isLoading}>
      <Card extraClasses="flex gap-4 md:gap-[22px] flex-col">
        <div className="flex justify-between items-center flex-col-reverse md:flex-row gap-[15px]">
          <Skeleton.Loader className="w-[100px] h-5" containerClassName="h-5">
            <span className="text-white text-xl">
              {isChecked ? (
                <div>
                  Events
                  <span className="text-offWhite text-base">
                    {' '}
                    ({eventData?.listEvents.count || 0})
                  </span>
                </div>
              ) : (
                <div>
                  Extrinsics
                  <span className="text-offWhite text-base">
                    {' '}
                    ({extrinsicsData?.getExtrinsics.count || 0})
                  </span>
                </div>
              )}
            </span>
          </Skeleton.Loader>
          <Skeleton.Loader className="md:w-[200px] w-full h-10" containerClassName="h-10">
            <Switches
              onChange={handleCheckboxChange}
              isChecked={isChecked}
              counts={{
                eventCount: (eventData && eventData.listEvents.count) || '',
                extrinsicsCount: (extrinsicsData && extrinsicsData?.getExtrinsics.count) || '',
              }}
            />
          </Skeleton.Loader>
        </div>
        <Table
          columns={isChecked ? EVENTS_COLUMNS : EXTRINSICS_COLUMNS}
          hasHeader
          data={!isLoading ? data : arrayOfSize(2)}
          classNames={{
            row: isChecked
              ? 'grid gap-x-2 grid-cols-2 md:grid-cols-[minmax(100px,20%)_minmax(100px,30%)_minmax(100px,20%)_minmax(100px,20%)] border-0'
              : 'grid gap-x-2 grid-cols-2 md:grid-cols-[minmax(140px,20%)_minmax(120px,25%)_minmax(130px,15%)_minmax(130px,12%)_minmax(225px,25%)_minmax(15px,3%)] border-0',
            columns: '!justify-start',
          }}
          isAccordion
          isLoading={isLoading}
          key={isChecked ? 'event' : 'extrinsic'}
        />
        {isChecked ? eventPageController : extrinsicPageController}
      </Card>
    </Skeleton.Provider>
  );
}

export default BlockRelationalData;
