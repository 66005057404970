import { Fragment } from 'react';

import { Details, Summary } from 'components/common/CustomAccordian';
import JsonTable from 'components/common/JsonTable';
import { get } from 'lodash';
import { ColumnsType } from 'types';
import { classNames, classes } from 'utils';

import ColumnWrapper from './ColumnWrapper';
import RowWrapper from './RowWrapper';

type TableBodyProps = {
  classNames?: classNames<'row' | 'columns' | 'rowWrapper' | 'transfer'>;
  columns: ColumnsType[];
  data: any;
  isHeaderHidden: boolean;
  isAccordion: boolean;
  id?: string;
  uniqueKey?: string;
};

const TableBody = ({
  classNames,
  columns,
  data,
  isHeaderHidden,
  isAccordion,
  id,
  uniqueKey,
}: TableBodyProps) => {
  return (
    <div className={classNames?.base}>
      <div id={id} className={classNames?.rowWrapper}>
        {isAccordion
          ? data?.map((row: any, idx: number) => {
              return (
                <Details
                  key={`accordion-${row?.[uniqueKey!] || idx}`}
                  open={false}
                  className="border-x-0 border-t-0 z-40 mb-4 last:mb-0 sm:mb-0"
                >
                  <Summary
                    classNames={{
                      content: 'block',
                      base: 'py-0 border-none',
                      expandIcon: 'text-pink absolute right-0',
                    }}
                  >
                    <RowWrapper key={`row-${idx}`} className={classNames?.row}>
                      {columns.map((col: any, index: number) => {
                        return get(col, 'shouldWrap', true) ? (
                          <ColumnWrapper
                            key={`col-${idx}-${index}`}
                            title={col.title}
                            isHeaderHidden={isHeaderHidden}
                            classNames={classNames?.transfer || ''}
                          >
                            {col.render({ ...row, colIndex: index, columnKey: col.key })}
                          </ColumnWrapper>
                        ) : (
                          <Fragment key={`frag-${idx}-${index}`}>
                            {col.render({ ...row, columnKey: col.key })}
                          </Fragment>
                        );
                      })}
                    </RowWrapper>
                  </Summary>
                  <JsonTable index={`json-${idx}`} jsonData={row?.data || row?.args} />
                </Details>
              );
            })
          : data?.map((row: any, idx: number) => {
              return (
                <RowWrapper
                  key={`row-${row?.id || row?.number || idx}`}
                  className={classes(classNames?.row)}
                >
                  {columns.map((col: any, index: number) => {
                    return get(col, 'shouldWrap', true) ? (
                      <ColumnWrapper
                        key={`col-${row?.id || row?.number || idx}-${index}`}
                        title={col.title}
                        isHeaderHidden={isHeaderHidden}
                        classNames={classNames?.transfer || ''}
                      >
                        {col.render({ ...row, colIndex: index, columnKey: col.key })}
                      </ColumnWrapper>
                    ) : (
                      <Fragment key={`frag-${row?.id || row?.number || idx}-${index}`}>
                        {col.render({ ...row, columnKey: col.key })}
                      </Fragment>
                    );
                  })}
                </RowWrapper>
              );
            })}
      </div>
    </div>
  );
};

export default TableBody;
