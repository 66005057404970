import { useState } from 'react';

import ExtrinsicsColumns from 'components/common/ExtrinsicsColumns';
import { DEFAULT_PAGE_SIZE } from 'consts';
import { useApiAccess } from 'contexts/AccessProvider';
import { useGetExtrinsicsQuery, useTransfersListQuery } from 'gql';
import { usePagination } from 'hooks';

import useAccountDetails from '../useAccountDetails';

import BalanceTransfer from './BalanceTransfer';

export const useAccountRelationalData = () => {
  const { address } = useAccountDetails();
  const { sessionKey } = useApiAccess();
  const [selectedTab, setSelectedTab] = useState<'extrinsics' | 'transfers'>('transfers');

  const EXTRINSICS_COLUMNS = ExtrinsicsColumns();

  const {
    PageController: ExtrinsicPageController,
    setTotalDataCount: setExtrinsicTotalDataCount,
    pageSize: ExtrinsicPageSize,
    pageNumber: extrinsicPageNumber,
  } = usePagination({
    defaultPageSize: DEFAULT_PAGE_SIZE,
  });

  const { data: extrinsicData, loading: isExtrinsicsLoading } = useGetExtrinsicsQuery({
    variables: {
      sessionKey,
      signer: address,
      page: { size: ExtrinsicPageSize, number: extrinsicPageNumber },
    },
    onCompleted(data) {
      setExtrinsicTotalDataCount(data.getExtrinsics.count);
    },
    fetchPolicy: 'cache-first',
    skip: !address || selectedTab !== 'extrinsics',
  });

  const TRANSFERS_COLUMNS = BalanceTransfer();

  const {
    PageController: TransferPageController,
    setTotalDataCount,
    pageSize: TransferPageSize,
    pageNumber: TransferPageNumber,
  } = usePagination({
    defaultPageSize: DEFAULT_PAGE_SIZE,
  });

  const { data: transferData, loading: isTransferLoading } = useTransfersListQuery({
    variables: {
      sessionKey,
      publicAddress: address,
      page: { size: TransferPageSize, number: TransferPageNumber },
    },
    fetchPolicy: 'cache-and-network',
    skip: !address,
    onCompleted(data) {
      setTotalDataCount(data.transfersList!.count);
    },
  });

  const tableData = {
    extrinsics: {
      columns: EXTRINSICS_COLUMNS,
      data: extrinsicData?.getExtrinsics,
      isLoading: isExtrinsicsLoading,
      count: extrinsicData?.getExtrinsics.count,
      PageController: ExtrinsicPageController,
      pageSize: ExtrinsicPageSize,
    },
    transfers: {
      columns: TRANSFERS_COLUMNS,
      data: transferData?.transfersList,
      isLoading: isTransferLoading,
      count: transferData?.transfersList?.count,
      PageController: TransferPageController,
      pageSize: TransferPageSize,
    },
  };

  return { selectedTab, setSelectedTab, tableData };
};
