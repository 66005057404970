import { Dispatch, SetStateAction } from 'react';

interface SwitchesProps {
  isActive: boolean;
  setIsActive: Dispatch<SetStateAction<boolean>>;
}

const Switches = ({ isActive, setIsActive }: SwitchesProps) => {
  return (
    <>
      <label className="shadow-card relative inline-flex cursor-pointer select-none items-center justify-center rounded-[25px] p-1 border-[1px] border-solid border-[#FFFFFF33] w-full md:w-auto">
        <span
          className={`flex items-center space-x-[6px] rounded-[32px] py-[5px] px-4 text-sm font-medium text-white w-full md:w-auto justify-center ${
            isActive ? 'text-primary bg-[#1B1B1B]' : ''
          }`}
          onClick={() => {
            setIsActive(true);
          }}
        >
          Active&nbsp;
        </span>
        <span
          className={`flex items-center space-x-[6px] rounded-[32px] py-[5px] px-4 text-sm font-medium text-white w-full md:w-auto justify-center ${
            !isActive ? 'text-primary bg-[#1B1B1B]' : ''
          }`}
          onClick={() => {
            setIsActive(false);
          }}
        >
          Others&nbsp;
        </span>
      </label>
    </>
  );
};

export default Switches;
