import { Icon } from '@analog/ui/src';
import allImgPaths from 'assets/allImgPaths';
import { Skeleton } from 'components/Skeleton';
import TableColumns from 'components/Table/TableColumns';
import { FontColors } from 'consts';
import { Link, useNavigate } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { ColumnData } from 'types';
import { classes, statusColor } from 'utils';
import { timeAgo } from 'utils/date';

const ExtrinsicsColumn = () => {
  const navigate = useNavigate();
  return [
    {
      title: 'Extrinsic ID',
      key: 'id',
      shouldWrap: false,
      render: (data: ColumnData) => (
        <div className="flex gap-[10px] col-span-2 md:col-span-1">
          <Icon icon={allImgPaths.dataArrowsIcon} iconName="extrinsic" />
          <div className={'flex flex-col justify-center overflow-hidden w-full'}>
            <TableColumns
              value={data[data.columnKey] || '-'}
              classNames={{ content: 'cursor-pointer w-fit' }}
              textColor={FontColors.PURPLE}
              label="Extrinsic"
              onClick={() =>
                data && navigate(`${routes.extrinsic}/${data[data.columnKey]?.replace('#', '')}`)
              }
            />
          </div>
        </div>
      ),
    },
    {
      title: 'Block',
      key: 'block_number',
      render: (data: ColumnData) => (
        <Link to={`${routes.block}/${data[data.columnKey]}`}>
          <TableColumns
            value={data[data.columnKey] || '-'}
            textColor={FontColors.PURPLE}
            classNames={{ content: 'cursor-pointer' }}
          />
        </Link>
      ),
    },
    {
      title: 'Extrinsic Hash',
      key: 'hash',
      render: (data: ColumnData) => (
        <TableColumns
          value={data[data.columnKey]}
          withTruncate
          withCopyButton
          colIndex={data.colIndex}
          dataTooltipContent="Copy Hash"
          dataTooltipId="hash"
          classNames={{ container: 'w-[100%] md:w-[90%]', content: 'text-white', base: 'w-10/12' }}
          copyValue={data[data.columnKey]}
        />
      ),
    },

    {
      title: 'Age',
      key: 'created_at',
      render: (data: ColumnData) => (
        <TableColumns
          value={data[data.columnKey] ? timeAgo(new Date(data[data.columnKey])) : '-'}
          classNames={{ content: 'text-white text-center' }}
        />
      ),
    },
    {
      title: 'Result',
      key: 'result',
      render: (data: ColumnData) => (
        <Skeleton.Loader>
          <div
            className={classes(
              `rounded-3xl py-[2px] px-[7px] inline-flex text-ellipsis overflow-hidden whitespace-nowrap w-fit`,
              statusColor(data[data.columnKey])
            )}
          >
            <span
              className={
                'text-[10px] rounded-3xl uppercase leading-[18px] text-ellipsis overflow-hidden whitespace-nowrap'
              }
            >
              {data[data.columnKey] || '-'}
            </span>
          </div>
        </Skeleton.Loader>
      ),
    },

    {
      title: 'Action',
      key: 'section',
      render: (data: ColumnData) => (
        <TableColumns
          value={`${data[data.columnKey]}(${data['method']})`}
          classNames={{
            content: 'text-white capitalize md:max-w-[210px]',
            base: 'md:justify-start w-full',
          }}
        />
      ),
    },
  ];
};

export default ExtrinsicsColumn;
