import { DEFAULT_PAGE_SIZE } from 'consts';
import { useApiAccess } from 'contexts/AccessProvider';
import { FilterType, useListExtrinsicsQuery } from 'gql';
import { useFilter, usePagination } from 'hooks';

import ExtrinsicsColumn from './components/ExtrinsicsColumn';

export function useExtrinsics() {
  const { sessionKey } = useApiAccess();
  const { PageController, setTotalDataCount, pageSize, pageNumber, setPageNumber } = usePagination({
    defaultPageSize: DEFAULT_PAGE_SIZE,
  });
  const { FilterController, selectedField } = useFilter(FilterType.Extrinsics, setPageNumber);

  const {
    data: extrinsicData,
    loading: isLoading,
    previousData,
  } = useListExtrinsicsQuery({
    variables: {
      sessionKey,
      page: { size: pageSize, number: pageNumber },
      filter: selectedField,
    },
    skip: selectedField.column ? !selectedField.value : false,
    onCompleted(data) {
      setTotalDataCount(data.listExtrinsics.count);
    },
    fetchPolicy: 'cache-and-network',
  });

  const COLUMNS = ExtrinsicsColumn();
  return {
    PageController,
    extrinsicsData: extrinsicData
      ? extrinsicData?.listExtrinsics.data
      : previousData?.listExtrinsics.data,
    isLoading,
    pageSize,
    pageNumber,
    COLUMNS,
    FilterController,
    totalExtrinsic: extrinsicData?.listExtrinsics.count,
  };
}
