import React from 'react';
import cn from '../../utils/classnames';
import { Skeleton } from '../Skeleton/Skeleton';

export type IconProps = {
  icon: string;
  extraClasses?: string;
  iconName?: string;
  iconClasses?: string;
  tooltipId?: string;
};

const Icon = React.forwardRef<HTMLDivElement, IconProps>(
  ({ icon, iconName = 'icon', extraClasses, iconClasses = '', tooltipId }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          'bg-white/[0.08] min-w-10 h-10 rounded-lg [box-shadow:0px_-3px_19px_0px_rgba(0,_0,_0,_0.25)_inset] flex justify-center items-center',
          extraClasses
        )}
      >
        <Skeleton.Loader
          className="h-[20px] w-[20px] !rounded-md  self-center"
          containerClassName="flex"
        >
          <img
            src={icon}
            alt={iconName}
            className={cn('w-[20px] h-[20px]', iconClasses)}
            data-tooltip-id={tooltipId}
          />
        </Skeleton.Loader>
      </div>
    );
  }
);

export default Icon;
