import { DetailsList } from '@analog/ui';
import { ResourceNotFound, SearchBox } from 'components';

import useChroniclesDetails from './useChroniclesDetails';

export function ChronicleDetails() {
  const { chronicleData, isLoading, error } = useChroniclesDetails();

  if (error) {
    return <ResourceNotFound page={'chronicles'} searchType={'Chronicles'} />;
  }

  return (
    <>
      <div className="flex flex-col gap-4 md:gap-8">
        <SearchBox defaultSearchType={'All'} />
      </div>
      <div className="md:mt-[40px] mt-[32px]">
        <DetailsList title={'Chronicle Info'} details={chronicleData} isLoading={isLoading} />
      </div>
    </>
  );
}

export default ChronicleDetails;
