import { Icon } from '@analog/ui/src';
import allImgPaths from 'assets/allImgPaths';
import { Text } from 'components';
import TableColumns from 'components/Table/TableColumns';
import { FontColors } from 'consts';
import { Link } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { ColumnData } from 'types';

interface EventsColumnsProps {
  isMobile: boolean;
}

const EventsColumns = ({ isMobile }: EventsColumnsProps) => {
  return [
    {
      title: 'Event Id',
      key: 'eventId',
      shouldWrap: false,
      render: (data: ColumnData) => (
        <div className="flex gap-[10px] col-span-2 md:col-span-1">
          <Icon
            icon={isMobile ? allImgPaths.dataArrowsIcon : allImgPaths.calendarTickIcon}
            iconName="profile"
          />
          <div className={'flex flex-col justify-center overflow-hidden w-full cursor-pointer'}>
            <TableColumns
              classNames={{ content: 'text-white' }}
              value={data[data.columnKey]}
              label={isMobile ? 'Events' : 'Event'}
            />
          </div>
        </div>
      ),
    },
    {
      title: 'Extrinsic Id',
      key: 'extrinsicId',
      render: (data: ColumnData) => (
        <Link
          to={`${
            !data[data.columnKey]?.includes('system')
              ? `${routes.extrinsic}/${data[data.columnKey] || '-'}`
              : ''
          }`}
        >
          <TableColumns
            value={data[data.columnKey] || '-'}
            textColor={FontColors.PURPLE}
            classNames={{ content: 'cursor-pointer' }}
          />
        </Link>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (data: ColumnData) => (
        <Text extraClass="text-white capitalize">{data[data.columnKey] || '-'}</Text>
      ),
    },
  ];
};

export default EventsColumns;
