import { useApolloClient } from '@apollo/client';
import { useApiAccess } from 'contexts/AccessProvider';
import {
  AccountDetailsDocument,
  useAccountBalanceUpdatedSubscription,
  useAccountDetailsQuery,
} from 'gql';
import { useParams } from 'react-router-dom';

import AccountData from './components/AccountData';

const useAccountDetails = () => {
  const { id } = useParams();
  const { sessionKey } = useApiAccess();
  const client = useApolloClient();

  const {
    data: account,
    loading: isAccountLoading,
    error,
  } = useAccountDetailsQuery({
    variables: {
      publicAddress: id || '',
      sessionKey,
    },
  });

  const accountData = AccountData({ data: account });

  useAccountBalanceUpdatedSubscription({
    variables: { address: account?.accountDetails?.address as string },
    skip: !account?.accountDetails.address,
    onData(options) {
      const balance = options.data.data?.accountBalanceUpdated?.balance;
      if (balance && account?.accountDetails) {
        client.writeQuery({
          query: AccountDetailsDocument,
          variables: {
            publicAddress: id || '',
            sessionKey,
          },
          data: {
            accountDetails: {
              ...account.accountDetails,
              data: {
                ...account.accountDetails.data,
                data: {
                  ...account.accountDetails.data.data,
                  free: balance,
                },
              },
            },
          },
        });
      }
    },
  });

  return {
    id: account?.accountDetails.publicAddress,
    address: account?.accountDetails.address,
    accountData,
    isAccountLoading,
    error,
    key: id,
  };
};
export default useAccountDetails;
