import { Fragment, useEffect } from 'react';

import allImgPaths from 'assets/allImgPaths';
import { Outlet, useLocation } from 'react-router-dom';
import { classes } from 'utils';

import NavBar from './NavBar';
import NewFooter from './NewFooter';

const Layout = () => {
  const path = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    if (!window.location.hash) {
      window.location.replace(window.location.origin + '/#/404');
    }
  }, [path]);

  return (
    <div className="bg-[#010101]">
      <div className="min-h-screen">
        <NavBar />
        <main
          className={classes(
            'pb-4 lg:pb-6 relative min-h-[calc(100vh_-_74px)] flex justify-between flex-col px-4 md:px-10 lg:px-[70px] xl:px-[120px] overflow-hidden'
          )}
        >
          <Fragment>
            <div className="absolute left-0 right-0 sm:p-0 md:p-4 md:pt-0 lg:p-6 lg:pt-0 will-change-transform">
              <img
                src={allImgPaths.upperBlob}
                className="w-full rounded-tl-3xl rounded-tr-3xl opacity-80"
                alt="blob"
              />
            </div>
            <div className="relative z-10 pt-[30px] sm:pt-[46px] md:pt-14 lg:pt-[45px] pb-10">
              <Outlet />
            </div>
            <NewFooter />
          </Fragment>
        </main>
      </div>
    </div>
  );
};

export default Layout;
