export function arrayOfSize(size: number): number[] {
  return new Array(size).fill(null).map((_, i) => i);
}

export function truncate(value: string | undefined, sideLength = 6): string {
  return value
    ? value.length > sideLength * 2
      ? `${value.substring(0, sideLength)}...${value.substring(value.length - sideLength)}`
      : value
    : '';
}

export function formatAmount(amount = '0', decimalPoint = 2) {
  const _amount = Number(amount.replaceAll(',', ''));
  return `${_amount ? (_amount / Math.pow(10, 12)).toFixed(decimalPoint) : 0} ANLOG`;
}

export const isJSONString = (str: string) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

export const SortColumnsBlock = [{ Block: 'number' }, { Status: 'status' }, { Age: 'created_at' }];

export const SortColumnsExtrinsics = [
  { 'Extrinsic ID': 'id' },
  { 'Block Number': 'block_number' },
  { Age: 'created_at' },
  { Action: 'section' },
];

export const SortColumnsMembers = [
  { 'Account ID': 'account_id' },
  { Network: 'network' },
  { State: 'state' },
  { 'Shard ID': 'shardId' },
];

export const SortColumnsGmp = [
  { 'Account ID': 'account_id' },
  { Network: 'network' },
  { State: 'state' },
  { 'Shard ID': 'shardId' },
];

export const SortColumnsValidators = [
  { 'Self Bonded': 'selfBonded' },
  { 'Total Bonded': 'totalBonded' },
  { 'Reward Points': 'eraRewardPoints' },
];

export const SortColumnsTask = [
  { Phase: 'phase' },
  { Type: 'type' },
  { Network: 'network' },
  { 'Shard ID': 'shardId' },
];

export const SortColumnsShard = [{ Network: 'network' }, { State: 'state' }, { 'Shard ID': 'id' }];

export const parseJson = (json?: string | null | undefined) => {
  try {
    return JSON.parse(json || '[]');
  } catch {
    return [json];
  }
};

export const getLinkFromNetwork = (
  path: string | undefined | null,
  network: string | undefined | null
) => {
  if (!path || !network) return undefined;
  const chain = JSON.parse(network)[0];
  switch (chain.toLowerCase()) {
    case 'ethereum':
      return `https://eth-sepolia.blockscout.com/${path}`;
    case 'astar':
      return `https://shibuya.blockscout.com/${path}`;
    case 'polygon':
      return `https://amoy.polygonscan.com/${path}`;
    default:
      return undefined;
  }
};

export const parseStringToArray = (sigString: any) => {
  let result = [];

  if (typeof sigString === 'string') {
    if (sigString.startsWith('[') && sigString.endsWith(']')) {
      const items = sigString.slice(1, -1).split(',');

      result = items.map((item) => item.trim().replace(/['"]+/g, ''));
    } else {
      result.push(sigString.replace(/['"]+/g, ''));
    }
  } else if (Array.isArray(sigString)) {
    result = sigString.map((item) => item.toString());
  }

  return result;
};

export function extractFirstValue(data: any) {
  if (Array.isArray(data)) {
    return data[0];
  }

  if (typeof data === 'string') {
    data = data.trim();

    if (data.startsWith('[') && data.endsWith(']')) {
      return data
        .slice(1, -1)
        .split(',')
        .map((item: string) => item.trim())[0];
    }
    return data.split(',').map((item: string) => item.trim())[0];
  }
  return '';
}

export type colorType = 'blue' | 'yellow' | 'green' | 'purple' | 'red' | 'brightGreen';

export const colors = {
  submitted: 'purple',
  sign: 'blue',
  write: 'yellow',
  read: 'green',
  failed: 'red',
  verified: 'brightGreen',
};

export const DBtoStringValueMapping = {
  sign: 'signed',
  write: 'processing',
  read: 'completed',
  submitted: 'submitted',
  verified: 'verified',
  failed: 'failed',
};
