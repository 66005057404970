import { Icon } from '@analog/ui/src';
import allImgPaths from 'assets/allImgPaths';
import { Text } from 'components';
import { Skeleton } from 'components/Skeleton';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { ColumnData } from 'types';
import { formatAmount, timeAgo } from 'utils';

const LatestTransfersColumn = () => {
  const navigate = useNavigate();
  return [
    {
      title: 'id',
      key: 'extrinsicId',
      shouldWrap: false,

      render: (data: ColumnData) => (
        <div className="flex gap-[10px] col-span-1">
          <div className={'flex flex-col overflow-hidden'}>
            <Skeleton.Loader className="lg:w-[100px] w-[50px]" containerClassName="h-5">
              <p className="text-sm">
                Extrinsic#{'  '}
                <Text
                  extraClass="text-pink text-base cursor-pointer w-fit"
                  onClick={() => {
                    navigate(`${routes.extrinsic}/${data[data.columnKey]}`);
                  }}
                >
                  {data[data.columnKey]}
                </Text>
              </p>
            </Skeleton.Loader>
            <Skeleton.Loader className="lg:w-[100px] w-[50px]" containerClassName="h-5">
              <div className="sm:flex gap-[10px]">
                <p className="text-sm flex gap-[10px] text-[#9D9D9D]">
                  <span className="text-sm text-[#9D9D9D]">From</span>
                  <Text
                    extraClass="text-pink text-sm flex items-center cursor-pointer"
                    onClick={() => navigate(`${routes.account}/${data.from}`)}
                  >
                    {data.from
                      ? data.from.substring(0, 6) +
                        '...' +
                        data.from.substring(data.from.length - 6)
                      : '-'}
                  </Text>
                </p>
                <p className="text-sm flex gap-[10px] text-[#9D9D9D]">
                  <span className="text-sm text-[#9D9D9D]">To</span>
                  <Text
                    extraClass="text-pink text-sm flex items-center cursor-pointer"
                    onClick={() => navigate(`${routes.account}/${data.to}`)}
                  >
                    {data.to
                      ? data.to.substring(0, 6) + '...' + data.to.substring(data.from.length - 6)
                      : '-'}
                  </Text>
                </p>
              </div>
            </Skeleton.Loader>
          </div>
        </div>
      ),
    },

    // {
    //   title: 'Result',
    //   key: 'extrinsicResult',
    //   render: (data: ColumnData) => (
    //     <div
    //       className={classes(
    //         `rounded-3xl py-[2px] px-[7px] inline-flex text-ellipsis overflow-hidden whitespace-nowrap w-fit`,
    //         statusColor(data[data.columnKey])
    //       )}
    //     >
    //       <span
    //         className={
    //           'text-[10px] rounded-3xl uppercase leading-[18px] text-ellipsis overflow-hidden whitespace-nowrap'
    //         }
    //       >
    //         {data[data.columnKey] || '-'}
    //       </span>
    //     </div>
    //   ),
    // },

    {
      title: 'Type',
      key: 'amount',

      render: (data: ColumnData) => (
        <div className={'flex flex-col overflow-hidden'}>
          <Skeleton.Loader className="lg:w-[90px] w-[50px]" containerClassName="h-5">
            <div className="flex items-center">
              <Text extraClass="text-white text-sm">
                {formatAmount(data[data.columnKey]) || '-'}{' '}
              </Text>
              <Icon icon={allImgPaths.greenTickCircleIcon} extraClasses="bg-transparent h-0" />
            </div>
            <Text extraClass="text-[#9D9D9D] text-sm">
              {data.created_at && timeAgo(new Date(data.created_at))}
            </Text>
          </Skeleton.Loader>
        </div>
      ),
    },
    // {
    //   title: 'from',
    //   key: 'from',
    //   render: (data: ColumnData) => (
    //     <div className="flex lg:block flex-col">
    //       <Skeleton.Loader className="lg:w-[110px] w-[60px]" containerClassName="h-5">
    //         <div className="flex gap-1 items-end">
    //           <span className="text-white text-sm">
    //             {data[data.columnKey]
    //               ? data[data.columnKey].substring(2, 10) +
    //                 '...' +
    //                 data[data.columnKey].substring(data[data.columnKey].length - 7)
    //               : '-'}
    //           </span>
    //         </div>
    //       </Skeleton.Loader>
    //       <Skeleton.Loader className="lg:w-[90px] w-[50px]" containerClassName="h-5">
    //         <span className="text-xs text-offWhite">From</span>
    //       </Skeleton.Loader>
    //     </div>
    //   ),
    // },
    // {
    //   title: 'To',
    //   key: 'to',
    //   render: (data: ColumnData) => (
    //     <div className="flex lg:block flex-col">
    //       <Skeleton.Loader className="lg:w-[110px] w-[60px]" containerClassName="h-5">
    //         <div className="flex gap-1 items-end">
    //           <span className="text-white text-sm">
    //             {data[data.columnKey]
    //               ? data[data.columnKey].substring(2, 10) +
    //                 '...' +
    //                 data[data.columnKey].substring(data[data.columnKey].length - 7)
    //               : '-'}
    //           </span>
    //         </div>
    //       </Skeleton.Loader>
    //       <Skeleton.Loader className="lg:w-[90px] w-[50px]" containerClassName="h-5">
    //         <span className="text-xs text-offWhite">To</span>
    //       </Skeleton.Loader>
    //     </div>
    //   ),
    // },
  ];
};
export default LatestTransfersColumn;
