import { Icon, Tooltip } from '@analog/ui/src';
import allImgPaths from 'assets/allImgPaths';
import { Card, SearchBox, Table } from 'components';
import { Skeleton } from 'components/Skeleton';
import { TerminologyData } from 'pages/Home/HomePage/components/Telemetry/Telemetry';
import { SortColumnsShard, arrayOfSize } from 'utils';

import useTransferList from './useTransferList';

const TransferList = () => {
  const {
    PageController,
    transferData,
    pageSize,
    isLoading,
    sortOrder,
    onSort,
    transferColumn,
    FilterController,
    totalTransfers,
  } = useTransferList();

  return (
    <>
      <div className="flex flex-col gap-4 md:gap-8 ">
        <div className="flex gap-2">
          <SearchBox defaultSearchType={'All'} />
          {FilterController}
        </div>
      </div>
      <div className="mt-8 md:mt-[60px]">
        <Card extraClasses="flex gap-4 md:gap-[22px] flex-col">
          <span className="text-white text-xl flex items-center">
            <Tooltip
              place="top-start"
              id={TerminologyData['transfer'].id}
              content={TerminologyData['transfer'].content}
              className="max-w-[340px] md:max-w-[540px] !text-black !rounded-lg z-10 !text-xs !px-[6px] !py-[6px] md:!px-[12px] md:!py-[10px] text-pretty"
            />
            Transfers{' '}
            <Skeleton.Loader>
              <span className="text-white/70 text-base pl-[6px]">({totalTransfers})</span>{' '}
            </Skeleton.Loader>
            <Icon
              icon={allImgPaths.infoIcon}
              iconClasses="h-[14px] w-[14px]"
              extraClasses="bg-transparent h-auto min-w-8 flex-none justify-center cursor-pointer"
              tooltipId={TerminologyData['transfer'].id}
            />
          </span>
          <Table
            columns={transferColumn}
            hasHeader
            data={!isLoading ? transferData : arrayOfSize(pageSize)}
            classNames={{
              row: 'grid gap-x-3 grid-cols-2 md:grid-cols-[minmax(130px,15%)_minmax(120px,15%)_minmax(140px,15%)_minmax(170px,25%)_minmax(170px,25%)_minmax(150px,5%)_minmax(70px,5%)]',
              columns: 'justify-start',
            }}
            onSort={onSort}
            sortOrder={sortOrder}
            sortOptions={SortColumnsShard}
            isLoading={isLoading}
          />
          {transferData?.length && PageController}
        </Card>
      </div>
      <Tooltip id={'hash'} />
    </>
  );
};

export default TransferList;
