import { DetailsList } from '@analog/ui/src';
import { ResourceNotFound, SearchBox } from 'components';
import { Skeleton } from 'components/Skeleton';

import AccountRelationData from './components/AccountRelationData';
import useAccountDetails from './useAccountDetails';

function AccountDetails() {
  const { accountData, isAccountLoading, error, key } = useAccountDetails();

  if (error) {
    return <ResourceNotFound page={'accounts'} searchType={'Accounts'} />;
  }

  return (
    <Skeleton.Provider isLoading={isAccountLoading}>
      <div className="flex flex-col gap-4 md:gap-8">
        <SearchBox defaultSearchType={'All'} />
      </div>
      <div className="flex flex-col mt-8 md:mt-[60px] gap-8 lg:gap-10" key={key}>
        <DetailsList title="Account Info" details={accountData} isLoading={isAccountLoading} />
        <AccountRelationData />
      </div>
    </Skeleton.Provider>
  );
}

export default AccountDetails;
