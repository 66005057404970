import { Tooltip } from '@analog/ui/src';
import { Card, SearchBox, Table } from 'components';
import { Skeleton } from 'components/Skeleton';
import { SortColumnsShard, arrayOfSize } from 'utils';

import useShards from './useShardList';

const ShardList = () => {
  const {
    PageController,
    shardData,
    pageSize,
    isLoading,
    sortOrder,
    onSort,
    shardColumn,
    FilterController,
  } = useShards();
  return (
    <>
      <div className="flex flex-col gap-4 md:gap-8">
        <div className="flex gap-2">
          <SearchBox defaultSearchType={'All'} />
          {FilterController}
        </div>
      </div>
      <div className="mt-8 md:mt-[60px]">
        <Card extraClasses="flex gap-4 md:gap-[22px] flex-col">
          <span className="text-white text-xl">Shards</span>
          <Table
            columns={shardColumn}
            hasHeader
            data={!isLoading ? shardData : arrayOfSize(pageSize)}
            classNames={{
              row: 'grid gap-x-2 grid-cols-2 md:grid-cols-[minmax(110px,20%)_minmax(90px,20%)_minmax(90px,20%)_minmax(100px,20%)_minmax(90px,20%)]',
              columns: 'justify-start',
            }}
            isLoading={isLoading}
            onSort={onSort}
            sortOrder={sortOrder}
            sortOptions={SortColumnsShard}
          />
          <Skeleton.Provider isLoading={isLoading}>
            {(isLoading || shardData?.length) && PageController}
          </Skeleton.Provider>
        </Card>
      </div>
      <Tooltip id={'hash'} />
    </>
  );
};

export default ShardList;
