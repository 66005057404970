import { HashIcon, Icon } from '@analog/ui';
import allImgPaths from 'assets/allImgPaths';
import { AnalogIcon, Text, TruncatedText } from 'components';
import JsonTable from 'components/common/JsonTable';
import { FontColors } from 'consts';
import { ExtrinsicDetailsQuery } from 'gql';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { classes, formatAmount, statusColor, timeAgo, truncate } from 'utils';
interface OverviewDetailsProps {
  extrinsicDetailsData: ExtrinsicDetailsQuery | undefined;
}
const OverviewDetails = ({ extrinsicDetailsData }: OverviewDetailsProps) => {
  const navigate = useNavigate();
  return [
    {
      icon: <Icon icon={allImgPaths.dataArrowsIcon} />,
      label: 'Extrinsic',
      value: (
        <div className="flex items-center gap-2">{extrinsicDetailsData?.extrinsicDetails?.id}</div>
      ),
    },
    {
      icon: <Icon icon={allImgPaths.clockIcon} />,
      label: 'Timestamp',
      value: extrinsicDetailsData?.extrinsicDetails?.created_at
        ? `${moment
            .utc(extrinsicDetailsData?.extrinsicDetails?.created_at)
            .format('YYYY-MM-DD hh:mm:ss (UTC)')}`
        : '-',
    },
    {
      icon: <Icon icon={allImgPaths.clockIcon} />,
      label: 'Block Time',
      value: extrinsicDetailsData?.extrinsicDetails?.blockTime
        ? `${timeAgo(new Date(extrinsicDetailsData?.extrinsicDetails?.blockTime))}`
        : '-',
    },
    {
      icon: <Icon icon={allImgPaths.hashTagOutlineIcon} />,
      label: 'Block Number',
      value: (
        <div className="flex items-center">
          <Text
            color={FontColors.PURPLE}
            extraClass="cursor-pointer"
            onClick={() =>
              navigate(`${routes.block}/${extrinsicDetailsData?.extrinsicDetails?.block_number}`)
            }
          >
            {extrinsicDetailsData?.extrinsicDetails?.block_number}
          </Text>
          <Icon icon={allImgPaths.greenTickCircleIcon} extraClasses="bg-transparent h-0" />
        </div>
      ),
    },
    {
      icon: <Icon icon={allImgPaths.heart2Icon} />,
      label: 'Life Time',
      collapsible: true,
      value: (
        <JsonTable
          index={extrinsicDetailsData?.extrinsicDetails?.id.toString() as string}
          jsonData={extrinsicDetailsData?.extrinsicDetails?.life_time}
        />
      ),
    },
    {
      icon: <Icon icon={allImgPaths.hashTagIcon} />,
      label: 'Extrinsic Hash',
      value: (
        <TruncatedText
          originalText={extrinsicDetailsData?.extrinsicDetails?.hash as string}
          withCopyButton
          width="100%"
        />
      ),
    },
    {
      icon: <Icon icon={allImgPaths.phaseIcon} />,
      label: 'Action',
      value: (
        <Text extraClass="text-white capitalize bg-[#8247E5] px-[10px] py-1 rounded-full">
          {extrinsicDetailsData?.extrinsicDetails?.section}(
          {extrinsicDetailsData?.extrinsicDetails?.method})
        </Text>
      ),
    },
    {
      icon: <Icon icon={allImgPaths.senderIcon} />,
      label: 'Sender/Signer',
      value: (
        <Text
          onClick={() =>
            navigate(`${routes.account}/${extrinsicDetailsData?.extrinsicDetails?.signer}`)
          }
        >
          <HashIcon
            value={extrinsicDetailsData?.extrinsicDetails?.signer}
            className="text-pink cursor-pointer"
            size={20}
            copyable
          />
        </Text>
      ),
    },
    {
      icon: <Icon icon={allImgPaths.transferIcon} />,
      label: 'Assets Transfer',
      value: (
        <div className="flex flex-col md:flex-row md:justify-between gap-1 md:gap-10 whitespace-nowrap">
          <div className="flex gap-1 sm:gap-4">
            <span>From</span>
            <span
              className="text-purple-500 cursor-pointer"
              onClick={() =>
                navigate(`${routes.account}/${extrinsicDetailsData?.extrinsicDetails?.from}`)
              }
            >
              <HashIcon
                className="text-pink"
                size={20}
                value={truncate(extrinsicDetailsData?.extrinsicDetails?.from || '-')}
              />
              {/* {truncate(extrinsicDetailsData?.extrinsicDetails?.from || '-', 6)} */}
            </span>
          </div>
          <div className="flex gap-1 sm:gap-4">
            <span>To</span>
            <span
              className="text-purple-500 cursor-pointer"
              onClick={() =>
                navigate(`${routes.account}/${extrinsicDetailsData?.extrinsicDetails?.to}`)
              }
            >
              <HashIcon
                className="text-pink"
                size={20}
                value={truncate(extrinsicDetailsData?.extrinsicDetails?.to || '-')}
              />
              {/* {truncate(extrinsicDetailsData?.extrinsicDetails?.to || '-', 6)} */}
            </span>
          </div>
          <div className="flex flex-row items-center gap-1">
            <AnalogIcon className="w-5 h-5" />
            {formatAmount(extrinsicDetailsData?.extrinsicDetails?.assets_transfer || '0', 6)}
          </div>
        </div>
      ),
      enable:
        formatAmount(extrinsicDetailsData?.extrinsicDetails?.assets_transfer || '0') !==
          '0 ANLOG' &&
        formatAmount(extrinsicDetailsData?.extrinsicDetails?.assets_transfer || '0') !==
          '0.00 ANLOG',
    },
    {
      icon: <Icon icon={allImgPaths.mathIcon} />,
      label: 'Estimated Fee',
      value: (
        <div className="flex flex-row items-center gap-1">
          <AnalogIcon className="w-5 h-5" />
          {formatAmount(extrinsicDetailsData?.extrinsicDetails?.estimated_fee || '0', 6)}
        </div>
      ),
      enable:
        formatAmount(extrinsicDetailsData?.extrinsicDetails?.assets_transfer || '0') !==
          '0 ANLOG' &&
        formatAmount(extrinsicDetailsData?.extrinsicDetails?.assets_transfer || '0') !==
          '0.00 ANLOG',
    },
    {
      icon: <Icon icon={allImgPaths.walletIcon} />,
      label: 'Used fee',
      value: (
        <div className="flex flex-row items-center gap-1">
          <AnalogIcon className="w-5 h-5" />
          {formatAmount(extrinsicDetailsData?.extrinsicDetails?.used_fee || '0', 6)}
        </div>
      ),
      enable:
        formatAmount(extrinsicDetailsData?.extrinsicDetails?.assets_transfer || '0') !==
          '0 ANLOG' &&
        formatAmount(extrinsicDetailsData?.extrinsicDetails?.assets_transfer || '0') !==
          '0.00 ANLOG',
    },
    {
      icon: <Icon icon={allImgPaths.clipboardCloseIcon} />,
      label: 'Nonce',
      value: extrinsicDetailsData?.extrinsicDetails?.nonce,
    },
    {
      icon: <Icon icon={allImgPaths.clipboardTickIcon} />,
      label: 'Result',
      value: (
        <div
          className={classes(
            `rounded-3xl py-[2px] px-[7px] inline-flex text-ellipsis overflow-hidden whitespace-nowrap w-fit`,
            statusColor(extrinsicDetailsData?.extrinsicDetails?.result || '')
          )}
        >
          <span
            className={
              'text-[10px] rounded-3xl uppercase leading-[18px] text-ellipsis overflow-hidden whitespace-nowrap'
            }
          >
            {extrinsicDetailsData?.extrinsicDetails?.result || '-'}
          </span>
        </div>
      ),
    },
    {
      icon: <Icon icon={allImgPaths.parametersIcon} />,
      label: 'Parameters',
      collapsible: true,
      value: (
        <JsonTable
          index={extrinsicDetailsData?.extrinsicDetails?.id.toString() as string}
          jsonData={extrinsicDetailsData?.extrinsicDetails?.args}
        />
      ),
    },
    {
      icon: <Icon icon={allImgPaths.graphIcon} />,
      label: 'Signature',
      value: (
        <TruncatedText
          originalText={extrinsicDetailsData?.extrinsicDetails?.signature || ''}
          width="80%"
          withCopyButton
        />
      ),
    },
  ];
};

export default OverviewDetails;
