import { Icon } from '@analog/ui/src';
import allImgPaths from 'assets/allImgPaths';
import { AnalogIcon, Text } from 'components';
import { Skeleton } from 'components/Skeleton';
import TableColumns from 'components/Table/TableColumns';
import { FontColors } from 'consts';
import { Link } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { ColumnData } from 'types';

const transformToNumber = (td: string) => Number(td).toFixed(2);

const ValidatorColumn = () => {
  return [
    {
      title: 'Validator',
      key: 'address',
      render(data: ColumnData) {
        return (
          <Link
            className="flex gap-[10px] col-span-2 md:col-span-1 cursor-pointer items-center"
            to={`${routes.validator}/${data[data.columnKey]}`}
            state={{ data: data }}
          >
            <Icon icon={allImgPaths.addressIcon} />
            <div className={'flex flex-col overflow-hidden w-full'}>
              <TableColumns
                value={data[data.columnKey]}
                withTruncate
                withCopyButton
                textColor={FontColors.PURPLE}
              />
            </div>
          </Link>
        );
      },
    },
    {
      title: 'Self Bonded',
      key: 'selfBonded',
      render(data: ColumnData) {
        return (
          <Skeleton.Loader className="flex md:justify-end" containerClassName="flex">
            <div className="flex flex-row items-center gap-1">
              <AnalogIcon className="w-5 h-5" />
              <Text extraClass="text-white text-sm">{data[data.columnKey]} ANLOG</Text>
            </div>
          </Skeleton.Loader>
        );
      },
    },
    {
      title: 'Total Bonded',
      key: 'totalBonded',
      render(data: ColumnData) {
        return (
          <Skeleton.Loader className="flex md:justify-end" containerClassName="flex">
            <div className="flex flex-row items-center gap-1">
              <AnalogIcon className="w-5 h-5" />
              <Text extraClass="text-white text-sm">{data[data.columnKey]} ANLOG</Text>
            </div>
          </Skeleton.Loader>
        );
      },
    },
    {
      title: 'Commission',
      key: 'commission',
      render(data: ColumnData) {
        return <Text extraClass="text-white text-sm">{data[data.columnKey]}</Text>;
      },
    },
    {
      title: 'Reward Points',
      key: 'eraRewardPoints',
      render(data: ColumnData) {
        return (
          <Skeleton.Loader className="flex md:justify-end" containerClassName="flex">
            <Text extraClass="text-white text-sm flex">
              {transformToNumber(data[data.columnKey])}
            </Text>
          </Skeleton.Loader>
        );
      },
    },
    {
      title: 'Latest Mining',
      key: 'latestBlock',
      render(data: ColumnData) {
        return (
          <Link to={`${routes.block}/${data[data?.columnKey]}`} className="cursor-pointer">
            <Text color={FontColors.PURPLE} extraClass="text-sm">
              {data[data.columnKey]}
            </Text>
          </Link>
        );
      },
    },
  ];
};
export default ValidatorColumn;
