import { Dispatch, SetStateAction } from 'react';

interface SwitchesProps {
  selectedTab: 'latest' | 'largest';
  setSelectedTab: Dispatch<SetStateAction<'latest' | 'largest'>>;
}

const Switches = ({ setSelectedTab, selectedTab }: SwitchesProps) => {
  return (
    <>
      <label className="shadow-card relative inline-flex cursor-pointer select-none items-center justify-center rounded-[25px] p-1 bg-white/10 w-full md:w-auto">
        <span
          className={`flex items-center space-x-[6px] rounded-[32px] py-[3px] sm:py-[5px] px-2 sm:px-4 text-sm font-medium text-white w-full md:w-auto justify-center ${
            selectedTab === 'latest' ? 'bg-[#010101]' : 'text-white/40'
          }`}
          onClick={() => {
            setSelectedTab('latest');
          }}
        >
          Latest&nbsp;
        </span>
        <span
          className={`flex items-center space-x-[6px] rounded-[32px] py-[3px] sm:py-[5px] px-2 sm:px-4 text-sm font-medium text-white w-full md:w-auto justify-center ${
            selectedTab === 'largest' ? 'bg-[#010101]' : 'text-white/40'
          }`}
          onClick={() => {
            setSelectedTab('largest');
          }}
        >
          Large&nbsp;
        </span>
      </label>
    </>
  );
};

export default Switches;
