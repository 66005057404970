import { Icon } from '@analog/ui/src';
import allImgPaths from 'assets/allImgPaths';
import { AnalogIcon } from 'components';
import { Skeleton } from 'components/Skeleton';
import TableColumns from 'components/Table/TableColumns';
import { FontColors } from 'consts';
import { useApiAccess } from 'contexts/AccessProvider';
import { useAccountDetailsQuery } from 'gql';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { ColumnsType } from 'types';
import { classes, formatAmount, statusColor, timeAgo } from 'utils';

const BalanceTransfer = (): ColumnsType[] => {
  const { id } = useParams();
  const { sessionKey } = useApiAccess();
  const { data: accountData } = useAccountDetailsQuery({
    variables: {
      publicAddress: id || '',
      sessionKey: sessionKey,
    },
  });
  const navigate = useNavigate();
  return [
    {
      title: 'Index',
      key: 'extrinsicId',
      shouldWrap: false,
      render: (data: any) => (
        <div className="flex gap-[10px] col-span-2 md:col-span-1">
          <Skeleton.Loader className="h-10 w-10 !rounded-xl" containerClassName="h-10">
            <Icon icon={allImgPaths.dataArrowsIcon} iconName="profile" />
          </Skeleton.Loader>
          <div className={'flex flex-col overflow-hidden w-full'}>
            <TableColumns
              value={data[data.columnKey]}
              classNames={{ content: 'cursor-pointer w-fit' }}
              textColor={FontColors.PURPLE}
              label="Extrinsic"
              onClick={() => {
                data && navigate(`${routes.extrinsic}/${data[data.columnKey]}`);
              }}
            />
          </div>
        </div>
      ),
    },
    {
      title: 'Block',
      key: 'blockNumber',
      shouldWrap: false,
      render: (data: any) => (
        <div className="flex gap-[10px] col-span-2 md:col-span-1">
          <Skeleton.Loader className="h-10 w-10 !rounded-xl" containerClassName="h-10">
            <Icon icon={allImgPaths.layerIcon} iconName="profile" />
          </Skeleton.Loader>
          <div className={'flex flex-col overflow-hidden w-full'}>
            <TableColumns
              value={data[data.columnKey]}
              classNames={{ content: 'cursor-pointer w-fit' }}
              textColor={FontColors.PURPLE}
              label="Block"
              onClick={() => {
                data && navigate(`${routes.block}/${data[data.columnKey]}`);
              }}
            />
          </div>
        </div>
      ),
    },
    {
      title: 'Time',
      key: 'created_at',
      render: (data: any) => (
        <TableColumns
          value={data[data.columnKey] ? timeAgo(new Date(data[data.columnKey])) : '-'}
          classNames={{ content: 'text-white', beforeIcon: 'h-4' }}
        />
      ),
    },
    {
      title: 'From',
      key: 'from',
      render: (data: any) => (
        <TableColumns
          value={data[data.columnKey] || '-'}
          withTruncate
          withCopyButton
          textColor={
            accountData?.accountDetails.address !== data[data.columnKey]
              ? FontColors.PURPLE
              : FontColors.WHITE
          }
          classNames={{
            content: `${
              accountData?.accountDetails.address !== data[data.columnKey] && 'cursor-pointer'
            }`,
            beforeIcon: 'h-4',
          }}
          onClick={() => {
            accountData && navigate(`${routes.account}/${data[data.columnKey]}`);
          }}
        />
      ),
    },
    {
      title: 'To',
      key: 'to',
      render: (data: any) => (
        <TableColumns
          value={data[data.columnKey] || '-'}
          withTruncate
          withCopyButton
          textColor={
            accountData?.accountDetails.address !== data[data.columnKey]
              ? FontColors.PURPLE
              : FontColors.WHITE
          }
          classNames={{
            content: `${
              accountData?.accountDetails.address !== data[data.columnKey] && 'cursor-pointer'
            }`,
            beforeIcon: 'h-4',
          }}
          onClick={() => {
            accountData && navigate(`${routes.account}/${data[data.columnKey]}`);
          }}
        />
      ),
    },
    {
      title: 'Amount',
      key: 'amount',
      render: (data: any) => (
        <Skeleton.Loader>
          <div className="flex flex-row items-center gap-1">
            <AnalogIcon className="min-w-4 w-4 min-h-4 h-4" />
            <TableColumns
              value={formatAmount(data[data.columnKey]) || '0'}
              classNames={{ content: 'text-white' }}
            />
          </div>
        </Skeleton.Loader>
      ),
    },
    {
      title: 'Result',
      key: 'extrinsicResult',
      render: (data: any) => (
        <Skeleton.Loader>
          <div
            className={classes(
              `rounded-3xl py-[2px] px-[7px] inline-flex text-ellipsis overflow-hidden whitespace-nowrap w-fit`,
              statusColor(data[data.columnKey])
            )}
          >
            <span
              className={
                'text-[10px] rounded-3xl uppercase leading-[18px] text-ellipsis overflow-hidden whitespace-nowrap'
              }
            >
              {data[data.columnKey] || '-'}
            </span>
          </div>
        </Skeleton.Loader>
      ),
    },
  ];
};

export default BalanceTransfer;
